const URL = `https://mg21.adsbnetwork.com/zsrv`;

/**
 * Fetch JSON data from a given URL and handle errors.
 * @param {string} url
 * @param {Object} [options={}] - Fetch options (e.g., method)
 * @returns {Promise<Object>} - Parsed JSON data
 */
async function fetchData(url, options = {}) {
  try {
    let response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error(`Fetch error: ${error.message}`);
    return null; // Optionally, return a default value or handle error appropriately
  }
}

/**
 * Request all flights
 * @returns {Promise<Object>}
 */
export function fetchFlights() {
  return fetchData(`${URL}/xenvolsrv.php?userid=betatesteur&serviceid=allicao`);
}

/**
 * Request all ships
 * @returns {Promise<Object>}
 */
export function fetchShips() {
  return fetchData(`https://ais.adsbnetwork.com/giver/vessels/live`, {
    method: "POST",
  });
}

/**
 * Request one ship by MMSI
 * @param {string} mmsi
 * @returns {Promise<Object>}
 */
export function fetchCruise(mmsi) {
  return fetchData(`https://ais.adsbnetwork.com/giver/vessels/${mmsi}?hours=24`, {
    method: "GET",
  });
}

/**
 * Request the icon list
 * @returns {Promise<Object>}
 */
export function fetchIcons() {
  return fetchData(`https://marceaudavid.github.io/radarvirtuel-icons/icons.json`);
}

/**
 * Request the path of a plane by its ICAO code
 * @param {string} icao
 * @returns {Promise<Object>}
 */
export function fetchPath(icao) {
  return fetchData(`${URL}/xenvolsrv.php?userid=betatesteur&serviceid=icao&icao=${icao}`);
}

/**
 * Request the last path of a plane by its ICAO code and timestamp
 * @param {string} icao
 * @param {number} timestamp
 * @returns {Promise<Object>}
 */
export function fetchLastPath(icao, timestamp) {
  return fetchData(`${URL}/xenvolsrv.php?userid=betatesteur&serviceid=icao&icao=${icao}&ptm=${timestamp}`);
}

/**
 * Request all stations
 * @returns {Promise<Object>}
 */
export function fetchStations() {
  return fetchData(`${URL}/Nlstsrv.php`);
}

/**
 * Request a station's coverage zone
 * @param {string} id
 * @returns {Promise<Object>}
 */
export function fetchZones(id) {
  return fetchData(`${URL}/NlstZrecep.php?serviceid=zonett&stid=${id.toLowerCase()}&typedt=geojson`);
}

