<template>
  <div
    class="absolute bottom-0 left-0 z-20 flex flex-col w-full h-auto p-2 sm:top-0 sm:bottom-auto sm:w-88 sm:h-full"
    :class="{ 'top-0 bottom-auto w-full h-full p-0': expand }"
  >
    <div v-if="expand && screen.width < 640" class="absolute top-0 right-0 m-3">
      <div class="bg-gray-100 rounded-full">
        <ChevronDownIcon class="text-black cursor-pointer" @click="onClose()" />
      </div>
    </div>
    <div v-if="getSelected.layer === 'ships' && expand" class="overlay">
      <div
        :style="{
          backgroundImage:
            'url(https://alpha.radarvirtuel.com/photos/' +
            this.getSelected.id +
            '.jpg)',
        }"
        class="flex-1 w-full h-48 bg-gray-300 bg-center bg-no-repeat bg-cover"
      />
      <div class="content">
        <div class="card">
          <div class="row">
            <div class="col">
              <span class="label">MMSI</span>
              <span class="value">{{ this.getSelected.id || "N/A" }}</span>
            </div>
            <div class="col">
              <span class="label">Callsign</span>
              <span class="value">{{
                this.getSelected.callsign || "N/A"
              }}</span>
            </div>
            <div class="col">
              <span class="label">Shipname</span>
              <span class="value">{{
                this.getSelected.shipname || "N/A"
              }}</span>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="row">
            <div class="col">
              <span class="label">Latitude</span>
              <span class="value">{{
                this.getSelected.coordinates[1].toFixed(5) || "N/A"
              }}</span>
            </div>
            <div class="col">
              <span class="label">Longitude</span>
              <span class="value">{{
                this.getSelected.coordinates[0].toFixed(5) || "N/A"
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">Length</span>
              <span class="value"
                >{{ this.getSelected.length || "N/A" }} m</span
              >
            </div>
            <div class="col">
              <span class="label">Width</span>
              <span class="value">{{ this.getSelected.width || "N/A" }} m</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">Bearing</span>
              <span class="value">{{ this.getSelected.course || "N/A" }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">Speed</span>
              <span class="value"
                >{{ this.getSelected.speed || "N/A" }} kts</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">Last Update</span>
              <span class="value">{{ date || "N/A" }}</span>
            </div>
            <div class="col">
              <span class="label">Elapsed Time</span>
              <span class="value">{{ time || "N/A" }}</span>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="row">
            <div class="col">
              <span class="label">Owner</span>
              <span class="value">{{ this.getSelected.owner || "N/A" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        getSelected.layer === 'flights' &&
          getSelected.category !== 'M' &&
          getSelected.id !== 'BAAA59' &&
          expand
      "
      class="overlay"
    >
      <div
        :style="{
          backgroundImage:
            'url(https://alpha.radarvirtuel.com/photos/' +
            this.getSelected.reg +
            '.jpg)',
        }"
        class="flex-1 w-full h-48 bg-gray-300 bg-center bg-no-repeat bg-cover"
      />
      <div class="content">
        <div class="card">
          <div class="row">
            <div class="col">
              <span class="label">ICAO</span>
              <span class="value">{{ this.getSelected.id || "N/A" }}</span>
            </div>
            <div class="col">
              <span class="label">Callsign</span>
              <span class="value">{{
                this.getSelected.callsign || "N/A"
              }}</span>
            </div>
            <div class="col">
              <span class="label">Reg</span>
              <span class="value">{{ this.getSelected.reg || "N/A" }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="this.getSelected.fromIcao && this.getSelected.toIcao"
          class="card"
        >
          <div class="row">
            <div class="col">
              <span class="label">Origin</span>
              {{ this.getSelected.fromIcao || "N/A" }}
            </div>
            <div class="col">
              <span class="label">Destination</span>
              {{ this.getSelected.toIcao || "N/A" }}
            </div>
          </div>
          <div class="row">
            <div class="col">{{ this.getSelected.fromName || "N/A" }}</div>
            <div class="col">{{ this.getSelected.toName || "N/A" }}</div>
          </div>
        </div>
        <div class="card">
          <div class="row">
            <div class="col">
              <span class="label">Latitude</span>
              <span class="value">{{
                this.getSelected.coordinates[1].toFixed(5) || "N/A"
              }}</span>
            </div>
            <div class="col">
              <span class="label">Longitude</span>
              <span class="value">{{
                this.getSelected.coordinates[0].toFixed(5) || "N/A"
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">Altitude</span>
              <span class="value"
                >{{ this.getSelected.altitude || "N/A" }} ft</span
              >
            </div>
            <div class="col">
              <span class="label">Bearing</span>
              <span class="value">{{ this.getSelected.bearing || "N/A" }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">Speed</span>
              <span class="value"
                >{{ this.getSelected.speed || "N/A" }} kts</span
              >
            </div>
            <div class="col">
              <span class="label">Vertical Speed</span>
              <span class="value"
                >{{ this.getSelected.verticalSpeed || "N/A" }} ft/min</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">Squawk</span>
              <span class="value">{{ this.getSelected.squawk || "N/A" }}</span>
            </div>
            <div class="col">
              <span class="label">Data Source</span>
              <span class="value">{{ this.dataSource || "N/A" }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">Last Update</span>
              <span class="value">{{ date || "N/A" }}</span>
            </div>
            <div class="col">
              <span class="label">Elapsed Time</span>
              <span class="value">{{ time || "N/A" }}</span>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="row">
            <div class="col">
              <span class="label">Operator Name</span>
              <span class="value">{{ this.getSelected.opName || "N/A" }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">Operator Icao</span>
              <span class="value">{{ this.getSelected.opIcao || "N/A" }}</span>
            </div>
            <div class="col">
              <span class="label">Type Code</span>
              <span class="value">{{
                this.getSelected.typeIcao || "N/A"
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">Category</span>
              <span class="value">{{ this.getSelected.usage || "N/A" }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">Model</span>
              <span class="value">{{ this.getSelected.model || "N/A" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        getSelected.layer === 'flights' &&
          (getSelected.category === 'M' || getSelected.id === 'BAAA59') &&
          expand
      "
      class="overlay"
    >
      <div
        :style="{
          backgroundImage:
            'url(https://alpha.radarvirtuel.com/photos/' +
            this.getSelected.reg +
            '.jpg)',
        }"
        class="flex-1 w-full h-48 bg-gray-300 bg-center bg-no-repeat bg-cover"
      />
      <div class="content">
        <div class="card">
          <div class="row">
            <div class="col">
              <span class="label">ID</span>
              <span class="value">{{
                this.getMobileStationData.id || "N/A"
              }}</span>
            </div>
            <div class="col">
              <span class="label">Country</span>
              <span class="value">{{
                this.getMobileStationData.country || "N/A"
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">City</span>
              <span class="value">{{
                this.getMobileStationData.city || "N/A"
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">Latitude</span>
              <span class="value">{{
                this.getSelected.coordinates[1].toFixed(5) || "N/A"
              }}</span>
            </div>
            <div class="col">
              <span class="label">Longitude</span>
              <span class="value">{{
                this.getSelected.coordinates[0].toFixed(5) || "N/A"
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">Altitude</span>
              <span class="value"
                >{{ this.getSelected.altitude || "N/A" }} ft</span
              >
            </div>
            <div class="col">
              <span class="label">Bearing</span>
              <span class="value">{{ this.getSelected.bearing || "N/A" }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">Speed</span>
              <span class="value"
                >{{ this.getSelected.speed || "N/A" }} kts</span
              >
            </div>
            <div class="col">
              <span class="label">Data Source</span>
              <span class="value">{{ this.dataSource || "N/A" }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">Last Update</span>
              <span class="value">{{ date || "N/A" }}</span>
            </div>
            <div class="col">
              <span class="label">Elapsed Time</span>
              <span class="value">{{ time || "N/A" }}</span>
            </div>
          </div>
        </div>
        <div v-if="planes.length > 0" class="card">
          <div class="row">
            <div class="flex items-center justify-center w-1/4 px-1 col">
              <span class="value">Type</span>
            </div>
            <div
              class="flex items-center justify-center w-1/4 px-1 cursor-pointer col"
              @click="onSort('reg')"
            >
              <span class="value">Reg.</span>
            </div>
            <div
              class="flex flex-row items-center justify-center w-1/4 px-1 cursor-pointer col"
              @click="onSort('callsign')"
            >
              <span class="value">Callsign</span>
            </div>
            <div
              class="flex flex-row items-center justify-center w-1/4 px-1 cursor-pointer col"
              @click="onSort('altitude')"
            >
              <span class="value">Alt.</span>
            </div>
          </div>
          <div
            class="cursor-pointer row"
            @click="onClick(plane)"
            v-for="plane in planes"
            :key="plane.id"
          >
            <div
              class="flex items-center justify-center w-1/4 px-1 border-r border-gray-300 col placeholder"
            >
              <img
                v-if="plane.typeIcao"
                class="w-full h-auto"
                :src="
                  `https://marceaudavid.github.io/radarvirtuel-icons/images/silhouette/${plane.typeIcao}.bmp`
                "
              />
            </div>
            <div class="flex items-center justify-center w-1/4 px-1 col">
              <span class="value">{{ plane.reg || "N/A" }}</span>
            </div>
            <div class="flex items-center justify-center w-1/4 px-1 col">
              <span class="value">{{ plane.callsign || "N/A" }}</span>
            </div>
            <div class="flex items-center justify-center w-1/4 px-1 col">
              <span class="value">{{ plane.altitude || "N/A" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="getSelected.layer === 'stations' && expand" class="overlay">
      <div
        :style="{ backgroundImage: 'url(' + this.getSelected.url + ')' }"
        class="flex-1 w-full h-56 bg-gray-300 bg-center bg-no-repeat bg-cover"
      />
      <div class="content">
        <div class="card">
          <div class="row">
            <div class="col">
              <span class="label">ID</span>
              <span class="value">{{ this.getSelected.id || "N/A" }}</span>
            </div>
            <div class="col">
              <span class="label">Country</span>
              <span class="value">{{ this.getSelected.country || "N/A" }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="label">City</span>
              <span class="value">{{ this.getSelected.city || "N/A" }}</span>
            </div>
          </div>
        </div>
        <div v-if="planes.length > 0" class="card">
          <div class="row">
            <div class="flex items-center justify-center w-1/4 px-1 col">
              <span class="value">Type</span>
            </div>
            <div
              class="flex items-center justify-center w-1/4 px-1 cursor-pointer col"
              @click="onSort('reg')"
            >
              <span class="value">Reg.</span>
            </div>
            <div
              class="flex flex-row items-center justify-center w-1/4 px-1 cursor-pointer col"
              @click="onSort('callsign')"
            >
              <span class="value">Callsign</span>
            </div>
            <div
              class="flex flex-row items-center justify-center w-1/4 px-1 cursor-pointer col"
              @click="onSort('altitude')"
            >
              <span class="value">Alt.</span>
            </div>
          </div>
          <div
            class="cursor-pointer row"
            @click="onClick(plane)"
            v-for="plane in planes"
            :key="plane.id"
          >
            <div
              class="flex items-center justify-center w-1/4 px-1 border-r border-gray-300 col placeholder"
            >
              <img
                v-if="plane.typeIcao"
                class="w-full h-auto"
                :src="
                  `https://marceaudavid.github.io/radarvirtuel-icons/images/silhouette/${plane.typeIcao}.bmp`
                "
              />
            </div>
            <div class="flex items-center justify-center w-1/4 px-1 col">
              <span class="value">{{ plane.reg || "N/A" }}</span>
            </div>
            <div class="flex items-center justify-center w-1/4 px-1 col">
              <span class="value">{{ plane.callsign || "N/A" }}</span>
            </div>
            <div class="flex items-center justify-center w-1/4 px-1 col">
              <span class="value">{{ plane.altitude || "N/A" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!expand"
      class="flex items-center justify-center w-full cursor-pointer"
    >
      <div
        @click="onExpand()"
        class="flex flex-col items-center justify-center w-10 bg-gray-100 rounded-t-full shadow"
      >
        <ChevronUpIcon class="text-black" />
      </div>
    </div>
    <div
      v-if="getSelected.layer === 'flights' && !expand"
      class="flex-1 w-full h-full overflow-hidden text-sm bg-gray-100 rounded-t shadow"
    >
      <div
        class="flex flex-row items-center justify-between border-b border-gray-300"
      >
        <div class="flex flex-col p-3">
          <span class="text-sm"
            >{{ this.getSelected.id || "N/A" }} /
            {{ this.getSelected.callsign || "N/A" }}</span
          >
          <span class="text-sm">{{ this.getSelected.opName || "N/A" }}</span>
        </div>
        <div
          :style="{
            backgroundImage:
              'url(https://alpha.radarvirtuel.com/photos/' +
              this.getSelected.reg +
              '.jpg)',
          }"
          class="w-32 h-16 bg-gray-100 bg-center bg-no-repeat bg-cover"
        />
      </div>
      <div class="flex flex-row border-b border-gray-300">
        <div class="flex-1 px-3 py-1 border-r border-gray-300">
          <span class="mr-2 text-gray-500">Reg</span>
          <span>{{ this.getSelected.reg || "N/A" }}</span>
        </div>
        <div class="flex-1 px-3 py-1">
          <span class="mr-2 text-gray-500">Type</span>
          <span>{{ this.getSelected.typeIcao || "N/A" }}</span>
        </div>
      </div>
      <div class="flex flex-row border-b border-gray-300">
        <div class="flex-1 px-3 py-1 border-r border-gray-300">
          <span class="mr-2 text-gray-500">Altitude</span>
          <span>{{ this.getSelected.altitude || "N/A" }} ft</span>
        </div>
        <div class="flex-1 px-3 py-1">
          <span class="mr-2 text-gray-500">Speed</span>
          <span>{{ this.getSelected.speed || "N/A" }} kts</span>
        </div>
      </div>
    </div>
    <div
      v-if="getSelected.layer === 'stations' && !expand"
      class="flex-1 w-full h-full overflow-hidden text-sm bg-gray-100 rounded-t shadow"
    >
      <div
        class="flex flex-row items-center justify-between border-b border-gray-300"
      >
        <div class="flex flex-col p-3">
          <span class="text-sm">{{ this.getSelected.id || "N/A" }}</span>
          <span class="text-sm">{{ this.planes.length }} planes tracked</span>
        </div>
        <div
          :style="{ backgroundImage: 'url(' + this.getSelected.url + ')' }"
          class="w-32 h-16 bg-gray-100 bg-center bg-no-repeat bg-cover"
        />
      </div>
      <div class="flex flex-row border-b border-gray-300">
        <div class="flex-1 px-3 py-1 border-r border-gray-300">
          <span class="mr-2 text-gray-500">City</span>
          <span>{{ this.getSelected.city || "N/A" }}</span>
        </div>
        <div class="flex-1 px-3 py-1">
          <span class="mr-2 text-gray-500">Country</span>
          <span>{{ this.getSelected.country || "N/A" }}</span>
        </div>
      </div>
    </div>
    <div
      class="relative bottom-0 left-0 h-20 bg-white border-t border-gray-300 rounded-b shadow"
    >
      <div
        v-if="getSelected.layer === 'flights' || getSelected.layer === 'ships'"
        class="flex flex-row items-center justify-around w-full h-full"
      >
        <button
          title="Follow flight"
          aria-label="Follow flight"
          class="flex flex-col items-center p-2 m-auto cursor-pointer"
          @click="onFollow($event)"
        >
          <CenterIcon :class="{ 'text-blue-500': follow }" />
          <p class="text-sm">Follow</p>
        </button>
        <button
          title="Show flight trace"
          aria-label="Show flight trace"
          class="flex flex-col items-center p-2 m-auto cursor-pointer"
          @click="onShowTrace($event)"
        >
          <RouteIcon :class="{ 'text-blue-500': showRoute }" />
          <p class="text-sm">Trace</p>
        </button>
      </div>
      <div
        v-if="getSelected.layer === 'stations'"
        class="flex flex-row items-center justify-around w-full h-full"
      >
        <button
          title="Focus station"
          aria-label="Focus station"
          class="flex flex-col items-center p-2 m-auto cursor-pointer"
          @click="onFocusStation($event)"
        >
          <CenterIcon :class="{ 'text-blue-500': focus }" />
          <p class="text-sm">Focus</p>
        </button>
        <button
          title="Show coverage zone"
          aria-label="Show coverage zone"
          class="flex flex-col items-center p-2 m-auto cursor-pointer"
          @click="onShowCoverage($event)"
        >
          <RadarIcon :class="{ 'text-blue-500': showZone }" />
          <p class="text-sm">Coverage</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CenterIcon from "../assets/icons/center.svg";
import RouteIcon from "../assets/icons/route.svg";
import ChevronUpIcon from "../assets/icons/chevron-up.svg";
import ChevronDownIcon from "../assets/icons/chevron-down.svg";
import RadarIcon from "../assets/icons/radar.svg";

import { alphanum } from "../utils/alphanum";

import { mapState, mapGetters, mapMutations } from "vuex";
import { timestampToDate } from "../utils/convert";
import bbox from "@turf/bbox";

export default {
  name: "SideBar",
  components: {
    CenterIcon,
    RouteIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    RadarIcon,
  },
  computed: {
    ...mapState([
      "map",
      "flights",
      "ships",
      "stations",
      "expand",
      "screen",
      "showZone",
      "camera",
    ]),
    ...mapGetters([
      "path",
      "isSelected",
      "getSelected",
      "getMobileStationData",
    ]),
    date() {
      return timestampToDate(this.getSelected.time);
    },
    time() {
      let time = Math.round(
        (Date.now() - new Date(this.getSelected.time)) / 1000
      );
      if (time >= 3600) {
        let hours = Math.floor(time / 3600);
        time %= 3600;
        let minutes = Math.floor(time / 60);
        let seconds = time % 60;
        return `${hours}h${minutes}m${seconds}s`;
      } else if (time >= 60) {
        let minutes = Math.floor(time / 60);
        let seconds = time % 60;
        return `${minutes}m${seconds}s`;
      } else {
        let seconds = time % 60;
        return `${seconds}s`;
      }
    },
    dataSource() {
      if (
        this.getSelected.stations[0] === "mlat" ||
        this.getSelected.stations[0] === "MLAT" ||
        this.getSelected.stations[0] === "MLATSRV"
      ) {
        return "MLAT";
      } else if (this.getSelected.stations.length > 0) {
        return "ADS-B";
      } else {
        return undefined;
      }
    },
    planes() {
      let data;
      if (
        this.getSelected.layer === "flights" &&
        (this.getSelected.category === "M" || this.getSelected.id === "BAAA59")
      ) {
        data = this.getMobileStationData;
      } else {
        data = this.getSelected;
      }
      let flights = data.flights.map((id) => this.flights[id]);
      if (this.field === "altitude") {
        flights.sort((a, b) => {
          if (a.altitude < b.altitude) return -1;
          if (a.altitude > b.altitude) return 1;
          return 0;
        });
      } else {
        flights.sort((a, b) =>
          alphanum(a[this.field] || "N/A", b[this.field] || "N/A")
        );
      }
      if (this.reverse) {
        return flights.reverse();
      } else {
        return flights;
      }
    },
  },
  data() {
    return {
      follow: false,
      showRoute: false,
      focus: false,
      stop: null,
      reverse: false,
      field: "altitude",
    };
  },
  mounted() {
    if (this.getSelected.layer === "stations") {
      this.onFocusStation();
    }
    if (
      this.getSelected.layer === "flights" &&
      (this.getSelected.category === "M" || this.getSelected.id === "BAAA59")
    ) {
      this.onFollow();
    }
  },
  methods: {
    ...mapMutations([
      "setCamera",
      "setBounds",
      "setFilter",
      "removeFilter",
      "setSelected",
      "setPulse",
      "removeSelected",
      "toggleExpand",
      "toggleZone",
    ]),
    onClick(result) {
      this.setSelected({ id: result.id, layer: result.layer });
      this.$router.replace({
        path: `/${result.layer}/${result.id}`,
      });
      this.setPulse(this.getSelected);
      this.setCamera({
        longitude: result.coordinates[0],
        latitude: result.coordinates[1],
        zoom: 6,
        pitch: 0,
        bearing: 0,
      });
    },
    onFollow() {
      this.showRoute = false;
      this.stop ? this.stop() : null;
      this.removeFilter();
      this.follow = !this.follow;
      if (this.follow) {
        this.toggleExpand(false);
        this.setCamera({
          longitude: this.getSelected.coordinates[0],
          latitude: this.getSelected.coordinates[1],
          zoom: 14,
          pitch: 0,
          bearing: 0,
        });
        this.stop = this.$watch("getSelected", () => {
          this.setCamera({
            longitude: this.getSelected.coordinates[0],
            latitude: this.getSelected.coordinates[1],
            zoom: this.camera.zoom,
            pitch: 0,
            bearing: 0,
          });
        });
      } else {
        this.stop();
        this.setCamera({
          longitude: this.getSelected.coordinates[0],
          latitude: this.getSelected.coordinates[1],
          zoom: this.camera.zoom,
          pitch: 0,
          bearing: 0,
        });
      }
    },
    onShowTrace() {
      this.follow = false;
      this.stop ? this.stop() : null;
      this.showRoute = !this.showRoute;
      if (this.showRoute) {
        this.toggleExpand(false);
        if (this.getSelected.layer === "flights") {
          this.setFilter([
            "any",
            ["==", ["get", "id"], this.getSelected.id],
            ["in", ["get", "id"], ["literal", this.getSelected.stations]],
          ]);
        }
        let bounds = bbox(this.path).map((c) => c.toFixed(6));
        this.setBounds([
          [bounds[0], bounds[1]],
          [bounds[2], bounds[3]],
        ]);
      } else {
        this.removeFilter();
        this.setCamera({
          longitude: this.getSelected.coordinates[0],
          latitude: this.getSelected.coordinates[1],
          zoom: this.camera.zoom,
          pitch: 0,
          bearing: 0,
        });
      }
    },
    onFocusStation() {
      this.focus = !this.focus;
      if (this.focus) {
        this.setFilter([
          "any",
          ["==", ["get", "id"], this.getSelected.id],
          ["in", ["get", "id"], ["literal", this.getSelected.flights]],
        ]);
        this.setCamera({
          longitude: this.getSelected.coordinates[0],
          latitude: this.getSelected.coordinates[1],
          zoom: 14,
        });
      } else {
        this.removeFilter();
      }
    },
    onShowCoverage() {
      this.toggleZone(!this.showZone);
    },
    onSort(field) {
      if (field === this.field) {
        this.reverse = !this.reverse;
      }
      this.field = field;
    },
    onClose() {
      this.toggleExpand(false);
    },
    onExpand() {
      this.toggleExpand(true);
    },
  },
};
</script>

<style lang="postcss">
.overlay {
  @apply flex-1 w-full h-full overflow-y-auto text-sm bg-gray-100 rounded-t shadow;
}

.content {
  @apply w-full h-auto p-4;
}

.placeholder {
  background-color: #d9ebf9;
}

.card {
  @apply flex flex-col mb-4 bg-white rounded shadow;
}

.row {
  @apply flex flex-row justify-between leading-tight text-gray-900 border-b border-gray-300 rounded-none;
}

.row:first-child {
  @apply rounded-t;
}

.row:last-child {
  @apply rounded-b border-0;
}

.col {
  @apply flex flex-1 flex-col p-3 justify-start items-start border-r border-gray-300;
}

.col:last-child {
  @apply border-0;
}

.label {
  @apply text-xs text-gray-500 mb-1;
}

.value {
  @apply text-sm;
}
</style>
